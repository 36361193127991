import React from 'react';

export function DateListenedIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 4C6.68629 4 4 6.68629 4 10H6.33333C7.25381 10 8 10.7462 8 11.6667V16.3333C8 17.2538 7.25381 18 6.33333 18H3.66667C2.74619 18 2 17.2538 2 16.3333V10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10V16.3333C18 17.2538 17.2538 18 16.3333 18H13.6667C12.7462 18 12 17.2538 12 16.3333V11.6667C12 10.7462 12.7462 10 13.6667 10H16C16 6.68629 13.3137 4 10 4ZM16 12H14V16H16V12ZM4 12H6V16H4V12Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function DateAddedIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M8.00871 8.85001C8.00871 9.48514 7.49384 10 6.85871 10C6.22358 10 5.70871 9.48514 5.70871 8.85001C5.70871 8.21488 6.22358 7.70001 6.85871 7.70001C7.49384 7.70001 8.00871 8.21488 8.00871 8.85001Z"
        fill="currentColor"
      />
      <path
        d="M6.85871 13.3103C7.49384 13.3103 8.00871 12.7954 8.00871 12.1603C8.00871 11.5251 7.49384 11.0103 6.85871 11.0103C6.22358 11.0103 5.70871 11.5251 5.70871 12.1603C5.70871 12.7954 6.22358 13.3103 6.85871 13.3103Z"
        fill="currentColor"
      />
      <path
        d="M11.15 8.85001C11.15 9.48514 10.6351 10 10 10C9.36488 10 8.85001 9.48514 8.85001 8.85001C8.85001 8.21488 9.36488 7.70001 10 7.70001C10.6351 7.70001 11.15 8.21488 11.15 8.85001Z"
        fill="currentColor"
      />
      <path
        d="M10 13.3103C10.6351 13.3103 11.15 12.7954 11.15 12.1603C11.15 11.5251 10.6351 11.0103 10 11.0103C9.36488 11.0103 8.85001 11.5251 8.85001 12.1603C8.85001 12.7954 9.36488 13.3103 10 13.3103Z"
        fill="currentColor"
      />
      <path
        d="M14.291 8.85001C14.291 9.48514 13.7761 10 13.141 10C12.5059 10 11.991 9.48514 11.991 8.85001C11.991 8.21488 12.5059 7.70001 13.141 7.70001C13.7761 7.70001 14.291 8.21488 14.291 8.85001Z"
        fill="currentColor"
      />
      <path
        d="M13.141 13.3103C13.7761 13.3103 14.291 12.7954 14.291 12.1603C14.291 11.5251 13.7761 11.0103 13.141 11.0103C12.5059 11.0103 11.991 11.5251 11.991 12.1603C11.991 12.7954 12.5059 13.3103 13.141 13.3103Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 1.62915C5.55228 1.62915 6 2.07687 6 2.62915V3.99596L14 3.99596V2.69238C14 2.1401 14.4477 1.69238 15 1.69238C15.5523 1.69238 16 2.1401 16 2.69238V3.99596C17.1046 3.99596 18 4.89139 18 5.99596V15C18 16.1046 17.1046 17 16 17L4 17C2.89543 17 2 16.1046 2 15V5.99596C2 4.89139 2.89543 3.99596 4 3.99596L4 2.62915C4 2.07687 4.44772 1.62915 5 1.62915ZM14.9099 5.99596C14.9396 5.99861 14.9696 5.99996 15 5.99996C15.0304 5.99996 15.0604 5.99861 15.0901 5.99596H16V15L4 15L4 5.99596H4.90862C4.93872 5.99869 4.9692 6.00008 5 6.00008C5.0308 6.00008 5.06128 5.99869 5.09138 5.99596L14.9099 5.99596Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function NameIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.20912 8.98681C2.61492 8.98681 2.19589 8.40392 2.3852 7.84068L4.11926 2.68141C4.2561 2.27428 4.63764 2 5.06715 2H5.90252V2.00485C6.2907 2.04317 6.62516 2.30545 6.75158 2.68082L8.48929 7.84031C8.679 8.40357 8.26 8.98681 7.66565 8.98681C7.27591 8.98681 6.93382 8.72736 6.82874 8.35205L6.57806 7.45678H4.29599L4.04633 8.35128C3.94146 8.72698 3.59917 8.98681 3.20912 8.98681ZM5.38929 3.53971H5.48128L6.2188 6.17369H4.65411L5.38929 3.53971Z"
        fill="currentColor"
      />
      <path
        d="M15 14.3844L16.3635 12.8635C16.715 12.512 17.2848 12.512 17.6363 12.8635C17.9878 13.215 17.9878 13.7848 17.6363 14.1363L14.6363 17.1363C14.4675 17.3051 14.2386 17.3999 13.9999 17.3999C13.7612 17.3999 13.5323 17.3051 13.3635 17.1363L10.3635 14.1363C10.012 13.7848 10.012 13.215 10.3635 12.8635C10.715 12.512 11.2848 12.512 11.6363 12.8635L13 14.3847L13 3.98682C13 3.43453 13.4477 2.98682 14 2.98682C14.5523 2.98682 15 3.43453 15 3.98682L15 14.3844Z"
        fill="currentColor"
      />
      <path
        d="M2.68113 17.138C2.68113 17.6068 3.06115 17.9868 3.52993 17.9868H7.47985C7.87451 17.9868 8.19445 17.6669 8.19445 17.2722C8.19445 16.8776 7.87451 16.5576 7.47985 16.5576H4.81766V16.4656L7.92214 12.3508C8.03283 12.204 8.09271 12.0253 8.09271 11.8415C8.09271 11.3744 7.71412 10.9958 7.2471 10.9958H3.44902C3.05436 10.9958 2.73442 11.3158 2.73442 11.7104C2.73442 12.1051 3.05436 12.425 3.44902 12.425H5.96102V12.5171L2.85299 16.626C2.74147 16.7734 2.68113 16.9532 2.68113 17.138Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function AscIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M3.45934 7.62652C3.06881 8.01705 3.06881 8.65021 3.45934 9.04073C3.84986 9.43126 4.48303 9.43126 4.87355 9.04073L9.0014 4.91288V17.4999C9.0014 18.0522 9.44912 18.4999 10.0014 18.4999C10.5537 18.4999 11.0014 18.0522 11.0014 17.4999V4.91573L15.1264 9.04074C15.5169 9.43126 16.1501 9.43126 16.5406 9.04074C16.9311 8.65021 16.9311 8.01705 16.5406 7.62652L10.7071 1.79298C10.3166 1.40246 9.6834 1.40246 9.29287 1.79299L3.45934 7.62652Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function DescIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M16.5406 12.3734C16.9312 11.9829 16.9312 11.3498 16.5406 10.9592C16.1501 10.5687 15.5169 10.5687 15.1264 10.9592L10.9986 15.0871L10.9986 2.50008C10.9986 1.9478 10.5508 1.50008 9.99856 1.50008C9.44627 1.50008 8.99856 1.9478 8.99856 2.50008L8.99856 15.0842L4.87355 10.9592C4.48303 10.5687 3.84986 10.5687 3.45934 10.9592C3.06882 11.3498 3.06882 11.9829 3.45934 12.3734L9.29288 18.207C9.6834 18.5975 10.3166 18.5975 10.7071 18.207L16.5406 12.3734Z"
        fill="currentColor"
      />
    </svg>
  );
}
