import { DateAddedIcon, DateListenedIcon, NameIcon } from 'assets/icons/library/SortIcons';

import { SelectOption } from '../types/item';

export enum LibrarySortBy {
  ALPHABETICAL = 'ALPHABETICAL',
  DATE_ADDED = 'DATE_ADDED',
  RECENTLY_LISTENED = 'RECENTLY_LISTENED'
}

export enum LibrarySortOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}

export const SORT_OPTIONS: SelectOption<LibrarySortBy>[] = [
  { value: LibrarySortBy.RECENTLY_LISTENED, label: 'Date listened', icon: <DateListenedIcon className="text-icn-txt-sec" /> },
  { value: LibrarySortBy.DATE_ADDED, label: 'Date added', icon: <DateAddedIcon className="text-icn-txt-sec" /> },
  { value: LibrarySortBy.ALPHABETICAL, label: 'Name', icon: <NameIcon className="text-icn-txt-sec" /> }
];

export type SortState = Record<LibrarySortBy, LibrarySortOrder | null>;
