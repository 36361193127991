import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Spinner } from 'assets/images';
import { IntegrationService } from 'interfaces/integrations';
import { actions as importActions } from 'store/import';

import { IntegrationIcons, IntegrationTitles } from './constants';
import { useIntegrationAuth } from './hooks/useIntegrationAuth';
import { ImportSourceCard } from './ImportSourceCard';

export function CanvasIntegrationCard() {
  const dispatch = useDispatch();
  const [authorizeData, isAuthorizing] = useIntegrationAuth(IntegrationService.CANVAS);

  const handleClick = useCallback(
    async (e: React.MouseEvent) => {
      if (authorizeData.authorized) return;

      e.stopPropagation();

      dispatch(importActions.openImportDialog({ source: IntegrationService.CANVAS }));
    },
    [authorizeData, dispatch]
  );

  return (
    <ImportSourceCard
      icon={IntegrationIcons[IntegrationService.CANVAS]}
      title={IntegrationTitles[IntegrationService.CANVAS].name}
      buttonType={authorizeData.authorized ? 'outline' : 'solid'}
      buttonText={
        authorizeData.authorized ? 'View Files' : isAuthorizing ? <Spinner width="20" fill="white" className="animate-spin inline-block" /> : 'Connect'
      }
      onClick={handleClick}
    />
  );
}
