import {
  type LiveQueryView,
  type ContentType as SDKContentType,
  type LibraryItem as SDKLibraryItemType,
  type Result as SDKResultType
} from '@speechifyinc/multiplatform-sdk';
import { WebAppImportFlow, WebAppImportType } from 'components/library/import/constants';
import { doc, getFirestore, setDoc } from 'firebase/firestore';

import { ErrorSource } from 'config/constants/errors';
import { RecordType } from 'interfaces/library';
import { logError } from 'lib/observability';
import { LibraryFilterAndSortOptions, LibraryFilterType, LibrarySortBy, LibrarySortOrder } from 'modules/library/constants';
import { ILibraryItem } from 'modules/library/types/item';

import { SDKContentSubType } from '../analytics/contentSubType';
import type { MultiplatformSDKInstance } from '../sdk';
import { SDKFacade } from './_base';
import { ContentMetaType, ListenableContent } from './listenableContent';

function unreachable(value: never) {
  return value;
}

export type LibraryFolderSubscription = {
  loadMoreItems: () => Promise<boolean>;
  cleanup: () => void;
  getCurrentItems: () => Promise<ILibraryItem[]>;
};

export class SDKLibraryFacade extends SDKFacade {
  private static _singleton: SDKLibraryFacade;
  constructor(sdk: MultiplatformSDKInstance) {
    super(sdk);
    SDKLibraryFacade._singleton = this;
  }

  static override get singleton(): SDKLibraryFacade {
    return SDKLibraryFacade._singleton;
  }

  public getItem = this.sdk.promisify(this.sdk.client.libraryService.getItem.bind(this.sdk.client.libraryService));

  public getChildrenItems = this.sdk.promisify(this.sdk.client.libraryService.getChildrenItems.bind(this.sdk.client.libraryService));

  public getCount = this.sdk.promisify(this.sdk.client.libraryService.getCount.bind(this.sdk.client.libraryService));

  public getRootFolder = this.sdk.promisify(this.sdk.client.libraryService.getRootFolder.bind(this.sdk.client.libraryService));

  public getTopItems = this.sdk.promisify(this.sdk.client.libraryService.getTopItems.bind(this.sdk.client.libraryService));

  public search = this.sdk.promisify(this.sdk.client.libraryService.search.bind(this.sdk.client.libraryService));

  public getTopLevelArchivedItems = this.sdk.promisify(this.sdk.client.libraryService.getTopLevelArchivedItems.bind(this.sdk.client.libraryService));

  public addDefaultLibraryItems = this.sdk.promisify(this.sdk.client.libraryService.addDefaultLibraryItems.bind(this.sdk.client.libraryService));

  public archiveItem = this.sdk.promisify(this.sdk.client.libraryService.archiveItem.bind(this.sdk.client.libraryService));

  public createFolder = this.sdk.promisify(this.sdk.client.libraryService.createFolder.bind(this.sdk.client.libraryService));

  public deleteAllArchivedItems = this.sdk.promisify(this.sdk.client.libraryService.deleteAllArchivedItems.bind(this.sdk.client.libraryService));

  public deleteItem = this.sdk.promisify(this.sdk.client.libraryService.deleteItem.bind(this.sdk.client.libraryService));

  public moveItem = this.sdk.promisify(this.sdk.client.libraryService.moveItem.bind(this.sdk.client.libraryService));

  public restoreItem = this.sdk.promisify(this.sdk.client.libraryService.restoreItem.bind(this.sdk.client.libraryService));

  public updateItem = this.sdk.promisify(this.sdk.client.libraryService.updateItem.bind(this.sdk.client.libraryService));

  // TODO(albertusdev): Consolidate this into ContentMetaType from ListenableContent
  private contentTypeToRecordType = (contentType: SDKContentType): RecordType => {
    const { ContentType } = this.sdk.sdkModule;
    switch (contentType) {
      case ContentType.SCAN:
        return RecordType.SCAN;
      case ContentType.EPUB:
        return RecordType.EPUB;
      case ContentType.PDF:
        return RecordType.PDF;
      case ContentType.DOCX:
        return RecordType.DEFAULT;
      case ContentType.HTML:
        return RecordType.WEB;
      case ContentType.TXT:
        return RecordType.TXT;
      default:
        return RecordType.DEFAULT;
    }
  };

  public toILibraryItem = (item: SDKLibraryItemType): ILibraryItem => {
    const { LibraryItem } = this.sdk.sdkModule;
    const base = {
      id: item.uri.id,
      dateAdded: new Date(item.createdAt)
    };
    if (item instanceof LibraryItem.Folder) {
      return {
        ...base,
        coverImagePath: '',
        imgAlt: '',
        title: item.title,
        progress: undefined,
        dateListened: undefined,
        recordType: RecordType.DEFAULT,
        isFolder: true,
        itemsCount: item.childrenCount
      };
    }
    if (item instanceof LibraryItem.Content) {
      return {
        ...base,
        coverImagePath: item.coverImageUrl || '',
        imgAlt: item.title,
        title: item.title,
        progress: item.listenProgressPercent ? Math.round(item.listenProgressPercent * 100) : undefined,
        dateListened: item.lastListenedAt ? new Date(item.lastListenedAt) : undefined,
        recordType: this.contentTypeToRecordType(item.contentType)
      };
    }
    // TODO(albertusdev): Add case for DeviceLocalContent here
    return {
      ...base,
      coverImagePath: '',
      imgAlt: '',
      title: '',
      progress: undefined,
      dateListened: undefined,
      recordType: RecordType.UNKNOWN
    };
  };

  public subscribeToFolder = async (
    options: {
      folderId: string;
    } & LibraryFilterAndSortOptions,
    callbacks: {
      onItemsChanged: (items: ILibraryItem[]) => void;
      onError?: (error: Error) => void;
    }
  ): Promise<LibraryFolderSubscription> => {
    try {
      const { Result } = this.sdk.sdkModule;

      const { FolderReferenceFactory, FilterAndSortOptions } = this.sdk.sdkModule;
      const view = await this.getChildrenItems(
        FolderReferenceFactory.fromId(options.folderId),
        new FilterAndSortOptions(this.translateFilterType(options.filterType), this.translateSortBy(options.sortBy), this.translateSortOrder(options.sortOrder))
      );

      // Set up the change listener
      const destructor = view.addChangeListener(async result => {
        if (result instanceof Result.Success) {
          const view = (result as SDKResultType.Success<LiveQueryView<SDKLibraryItemType>>).value;
          view.getCurrentItems(items => {
            callbacks.onItemsChanged(items.map(this.toILibraryItem));
          });
        } else {
          const error = (result as SDKResultType.Failure).error;
          if (callbacks.onError) {
            callbacks.onError(new Error(`Encountered error while subscribing to folder ${options.folderId}: ${error}`));
          }
        }
      });

      view.getCurrentItems(items => {
        callbacks.onItemsChanged(items.map(this.toILibraryItem));
      });

      // Return cleanup function
      return {
        cleanup: () => {
          destructor();
          view.destroy();
        },
        loadMoreItems: () => {
          return new Promise<boolean>(resolve => {
            // TODO(albertusdev): Check if Result.Failure is returned when there is no more items to load
            view.loadMoreItems(result => {
              resolve(result instanceof Result.Success);
            });
          });
        },
        getCurrentItems: () => {
          return new Promise<ILibraryItem[]>(resolve => {
            view.getCurrentItems(items => {
              resolve(items.map(this.toILibraryItem));
            });
          });
        }
      };
    } catch (error) {
      if (callbacks.onError) {
        callbacks.onError(error as Error);
      }
      logError(new Error(`Encountered error while subscribing to folder ${options.folderId}: ${error}`), ErrorSource.LIBRARY);
      return {
        cleanup: () => {},
        loadMoreItems: () => Promise.resolve(false),
        getCurrentItems: () => Promise.resolve([])
      };
    }
  };

  public async shareItem(itemId: string): Promise<string> {
    const item = await this.getItem(itemId);
    if (!item) {
      throw new Error('Item not found');
    }

    const shareId = `${itemId}-${Date.now()}`;
    const db = getFirestore();

    try {
      await setDoc(doc(db, 'sharedItems', shareId), {
        itemId,
        createdAt: new Date(),
        type: 'share'
      });

      return `${process.env.NEXT_PUBLIC_WEBAPP_URL}/shared/${shareId}`;
    } catch (error) {
      logError(error as Error, ErrorSource.LIBRARY);
      throw new Error('Failed to share item');
    }
  }

  public getItemAndWaitUntilListenable = async (itemId: string): Promise<SDKLibraryItemType> => {
    const SDKContent = this.sdk.sdkModule.LibraryItem.Content;

    const item = await this.getItem(itemId);
    if (item instanceof SDKContent) {
      if (!item.isInListenableState) {
        await new Promise<void>(resolve => {
          const interval = setInterval(async () => {
            const updatedItem = (await this.getItem(itemId)) as SDKLibraryItemType.Content;
            if (updatedItem.isInListenableState) {
              clearInterval(interval);
              resolve();
            }
          }, 1000);
        });
      }
      return item;
    }
    return item;
  };

  // ContentSubType is actually a string, but for easier and consolidated tracking we created enums here.
  public webAppImportFlowToSDKContentSubType = (importFlow: WebAppImportType) => {
    switch (importFlow) {
      case WebAppImportType.FILE_UPLOAD:
        return SDKContentSubType.LOCAL_FILES;
      case WebAppImportType.WEB_LINK:
        return SDKContentSubType.WEB_LINK;
      case WebAppImportType.TEXT:
        return SDKContentSubType.TYPE_OR_PASTE_TEXT;
      case WebAppImportType.GOOGLE_DRIVE:
        return SDKContentSubType.GOOGLE_DRIVE;
      case WebAppImportType.DROPBOX:
        return SDKContentSubType.DROPBOX;
      case WebAppImportType.ONE_DRIVE:
        return SDKContentSubType.ONE_DRIVE;
      case WebAppImportType.CANVAS:
        return SDKContentSubType.CANVAS;
      case WebAppImportType.AI_STORY:
        return SDKContentSubType.AI_STORY;
      default:
        return unreachable(importFlow);
    }
  };

  public listenableContentToSDKContentType = (listenableContent: ListenableContent) => {
    const metaType = listenableContent.metaType;
    const { ContentType } = this.sdk.sdkModule;
    switch (metaType) {
      case ContentMetaType.PDF:
        return ContentType.PDF;
      case ContentMetaType.DOCX:
        return ContentType.DOCX;
      case ContentMetaType.TXT:
        return ContentType.TXT;
      case ContentMetaType.HTML:
        return ContentType.HTML;
      case ContentMetaType.EPUB:
        return ContentType.EPUB;
      case ContentMetaType.SCAN:
        return ContentType.SCAN;
      case ContentMetaType.UNKNOWN:
        logError(
          new Error(
            `webAppImportFlowToSDKContentType error: Falling back to SDK ContentType.TXT due to unknown meta type for listenableContent: ${listenableContent.constructor.name}`
          ),
          ErrorSource.FILE_IMPORT
        );
        return ContentType.TXT;
      default:
        return unreachable(metaType);
    }
  };

  public webAppImportFlowToSDKImportFlow = (webAppImportFlow: WebAppImportFlow) => {
    const { ImportFlow } = this.sdk.sdkModule;
    switch (webAppImportFlow) {
      case WebAppImportFlow.PLUS_BUTTON_MODAL:
        return ImportFlow.PlusButtonModal;
      case WebAppImportFlow.PLUS_BUTTON_MODAL_DRAG_AND_DROP:
        return ImportFlow.PlusButtonModalDragAndDrop;
      case WebAppImportFlow.PLUS_BUTTON_MODAL_SELECT_FILES:
        return ImportFlow.PlusButtonModalSelectFiles;
      case WebAppImportFlow.LIBRARY_DRAG_AND_DROP:
        return ImportFlow.LibraryDragAndDrop;
      case WebAppImportFlow.LIBRARY_SELECT_FILES:
        return ImportFlow.LibrarySelectFiles;
      case WebAppImportFlow.LIBRARY_SCREEN_SUGGESTIONS:
        return ImportFlow.LibraryScreenSuggestions;
      case WebAppImportFlow.PILL_PLAYER:
        return ImportFlow.PillPlayer;
      case WebAppImportFlow.LISTENING_SCREEN:
        return ImportFlow.ListeningScreen;
      default:
        return unreachable(webAppImportFlow);
    }
  };

  public searchItems = async (query: string): Promise<ILibraryItem[]> => {
    if (!query) return [];
    const { SearchRequest, FilterType } = this.sdk.sdkModule;

    const searchResults = await this.search(new SearchRequest(query, [FilterType.RECORDS.Companion.all()]));
    return searchResults.items.map(this.toILibraryItem);
  };

  private translateSortBy(sortBy: LibrarySortBy) {
    const { SortBy } = this.sdk.sdkModule;
    switch (sortBy) {
      case LibrarySortBy.DATE_ADDED:
        return SortBy.DATE_ADDED;
      case LibrarySortBy.ALPHABETICAL:
        return SortBy.ALPHABETICAL;
      case LibrarySortBy.RECENTLY_LISTENED:
        return SortBy.RECENTLY_LISTENED;
      default:
        return unreachable(sortBy);
    }
  }

  private translateSortOrder(sortOrder: LibrarySortOrder) {
    const { SortOrder } = this.sdk.sdkModule;
    switch (sortOrder) {
      case LibrarySortOrder.ASC:
        return SortOrder.ASC;
      case LibrarySortOrder.DESC:
        return SortOrder.DESC;
      default:
        return unreachable(sortOrder);
    }
  }

  private translateFilterType(filterType: LibraryFilterType) {
    const { RecordType, FilterType } = this.sdk.sdkModule;
    switch (filterType) {
      case LibraryFilterType.ANY:
        return FilterType.ANY;
      case LibraryFilterType.RECORDS:
        return FilterType.RECORDS.Companion.all();
      case LibraryFilterType.FOLDERS:
        return FilterType.FOLDERS;
      case LibraryFilterType.TEXT:
        return FilterType.RECORDS.Companion.ofTypes([RecordType.TXT]);
      case LibraryFilterType.PDF:
        return FilterType.RECORDS.Companion.ofTypes([RecordType.PDF]);
      case LibraryFilterType.EPUB:
        return FilterType.RECORDS.Companion.ofTypes([RecordType.EPUB]);
      case LibraryFilterType.WEB_LINKS:
        return FilterType.RECORDS.Companion.ofTypes([RecordType.WEB]);
      case LibraryFilterType.SCANS:
        return FilterType.RECORDS.Companion.ofTypes([RecordType.SCAN]);
      default:
        return unreachable(filterType);
    }
  }
}
