import { AllFilesIcon, EPUBIcon, PDFsIcon, ScansIcon, TextDocsIcon, WebLinksIcon } from 'assets/icons/library/FilterIcons';
import FolderIcon from 'assets/icons/library/folder';

import { SelectOption } from '../types/item';

export enum LibraryFilterType {
  ANY = 'ANY',
  RECORDS = 'RECORDS',
  FOLDERS = 'FOLDERS',
  // TOP_LEVEL_ARCHIVED_ITEMS = 'TOP_LEVEL_ARCHIVED_ITEMS',
  // LISTENING_IN_PROGRESS_AND_NOT_STARTED = 'LISTENING_IN_PROGRESS_AND_NOT_STARTED',
  // LISTENING_IN_PROGRESS = 'LISTENING_IN_PROGRESS',
  // LISTENING_NOT_STARTED = 'LISTENING_NOT_STARTED',
  // LISTENING_FINISHED = 'LISTENING_FINISHED',
  // AVAILABLE_OFFLINE = 'AVAILABLE_OFFLINE'
  TEXT = 'TEXT',
  PDF = 'PDF',
  EPUB = 'EPUB',
  WEB_LINKS = 'WEB_LINKS',
  SCANS = 'SCANS'
}

export const FILTER_OPTIONS: SelectOption<LibraryFilterType>[] = [
  { value: LibraryFilterType.ANY, label: 'All Files', icon: <AllFilesIcon className="text-icn-txt-sec" /> },
  { value: LibraryFilterType.FOLDERS, label: 'Folders', icon: <FolderIcon width={20} height={20} className="text-icn-txt-sec" /> },
  { value: LibraryFilterType.PDF, label: 'PDFs', icon: <PDFsIcon className="text-icn-txt-sec" /> },
  { value: LibraryFilterType.TEXT, label: 'Text Docs', icon: <TextDocsIcon className="text-icn-txt-sec" /> },
  { value: LibraryFilterType.EPUB, label: 'ePub', icon: <EPUBIcon className="text-icn-txt-sec" /> },
  { value: LibraryFilterType.WEB_LINKS, label: 'Web Links', icon: <WebLinksIcon className="text-icn-txt-sec" /> },
  { value: LibraryFilterType.SCANS, label: 'Scans', icon: <ScansIcon className="text-icn-txt-sec" /> }
];
