import React from 'react';
export default function FolderIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5.3999 62.5182C5.3999 65.8247 7.59783 68.8428 10.9889 69.5807C16.2231 70.7199 26.6073 72.5 40.3999 72.5C54.1925 72.5 64.5767 70.7199 69.8109 69.5807C73.202 68.8428 75.3999 65.8247 75.3999 62.5182V25C75.3999 20.8579 72.042 17.5 67.8999 17.5L37.6306 17.5C35.871 17.5 34.241 16.5751 33.3384 15.0647L31.0011 11.153C29.6473 8.88733 27.2023 7.5 24.5629 7.5H12.8999C8.75777 7.5 5.3999 10.8579 5.3999 15V62.5182Z"
        fill="#3CC0FD"
      />
      <path
        d="M75.3999 23.8522C75.3999 23.8522 64.2193 27.5 40.3999 27.5C16.5805 27.5 5.3999 23.8522 5.3999 23.8522V14.4504C5.3999 10.6118 8.44686 7.5 12.2055 7.5H25.3773C27.7559 7.5 29.9619 8.76828 31.1957 10.8451L33.6538 14.9828C34.5548 16.4995 36.1884 17.4291 37.9525 17.4291L68.5943 17.4291C72.353 17.4291 75.3999 20.5409 75.3999 24.3794V23.8522Z"
        fill="#2B73FF"
      />
      <path
        d="M10.3999 25.0926C10.3999 22.9223 12.1593 21.1629 14.3296 21.1629L66.4702 21.1631C68.6405 21.1631 70.3999 22.9225 70.3999 25.0928C70.3999 25.0928 59.1739 27.4993 40.3998 27.4993C21.6256 27.4993 10.3999 25.0926 10.3999 25.0926Z"
        fill="white"
      />
    </svg>
  );
}
