import { DndContext, DragEndEvent, DragOverEvent, DragStartEvent, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToWindowEdges, snapCenterToCursor } from '@dnd-kit/modifiers';
import { ViewType } from 'interfaces';
import * as React from 'react';
import { useDispatch } from 'store';

import { useNavigate } from 'hooks/useNavigate';
import { actions as libraryActions } from 'store/library';
import { actions as mainPageActions } from 'store/mainPage';

type DragAndDropProps = {
  children: React.ReactNode;
  viewType?: ViewType;
};

// ESLint: 'viewType' is defined but never used
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const DragAndDrop: React.FC<DragAndDropProps> = ({ children, viewType }) => {
  const dispatch = useDispatch();
  // ESLint: 'navigate' is assigned a value but never used
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const navigate = useNavigate();
  const pointerSensor = useSensor(PointerSensor, { activationConstraint: { distance: 10 } });
  const sensors = useSensors(pointerSensor);

  const handleDragEnd = (e: DragEndEvent) => {
    dispatch(mainPageActions.setActiveDropTargetId(undefined));
    dispatch(mainPageActions.setIsDragging(false));

    if (e.active && e.over) {
      const activeDragItemId = (e.active.id as string).replace('library-item-', '').replace('folder-nav-', '').replace('-drag', '');
      const activeDropItemId = (e.over.id as string).replace('library-item-', '').replace('folder-nav-', '').replace('-drop', '');
      const dropTargets = e.active.data?.current?.targets || [];

      if (dropTargets.indexOf(activeDropItemId) > -1) {
        dispatch(
          libraryActions.setParentFolderId({
            itemId: activeDragItemId,
            parentFolderId: activeDropItemId,
            folderName: ''
          })
        );
      }

      if (activeDropItemId === 'trash') {
        dispatch(libraryActions.archiveItem({ itemId: activeDragItemId }));
      }

      if (activeDropItemId === 'root') {
        // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
        dispatch(libraryActions.setParentFolderId({ itemId: activeDragItemId, parentFolderId: null, folderName: 'My Library' }));
      }
    }
  };

  const handleDragOver = (e: DragOverEvent) => {
    if (e.over) {
      const activeDropItemId = (e.over.id as string).replace('library-item-', '').replace('folder-nav-', '').replace('-drop', '');
      const dropTargets = e.active.data?.current?.targets || [];

      if (dropTargets.indexOf(activeDropItemId) > -1 || activeDropItemId === 'trash' || activeDropItemId === 'root') {
        dispatch(mainPageActions.setActiveDropTargetId(activeDropItemId));
      } else {
        dispatch(mainPageActions.setActiveDropTargetId(undefined));
      }
    } else {
      dispatch(mainPageActions.setActiveDropTargetId(undefined));
    }
  };

  const handleDragStart = (e: DragStartEvent) => {
    const activeDragItemId = (e.active.id as string).replace('library-item-', '').replace('folder-nav-', '').replace('-drag', '');
    dispatch(mainPageActions.setActiveDragTargetId(activeDragItemId));
    dispatch(mainPageActions.setIsDragging(true));
  };

  const handleDragCancel = () => {
    dispatch(mainPageActions.setActiveDropTargetId(undefined));

    dispatch(mainPageActions.setActiveDragTargetId(undefined));
  };

  return (
    <DndContext
      modifiers={[snapCenterToCursor, restrictToWindowEdges]}
      onDragCancel={handleDragCancel}
      onDragEnd={handleDragEnd}
      onDragOver={handleDragOver}
      sensors={sensors}
      onDragStart={handleDragStart}
    >
      {children}
    </DndContext>
  );
};

export default DragAndDrop;
